import React from "react";
import { motion } from "framer-motion";
import {
    ShoppingBagIcon,
    StarIcon,
    ChartBarIcon,
} from "@heroicons/react/24/outline";

const features = [
    {
        name: "محل كراء الطوموبيلات أونلاين",
        description:
            "صاوب موقع خاص بيك و عرض فيه الطوموبيلات ديالك، وقبل الحجوزات 24/7.",
        icon: ShoppingBagIcon,
    },
    {
        name: "نظام التقييمات",
        description:
            "زيد المصداقية ديالك مع نظام تقييمات العملاء الموثوق. جلب كليان جدد و بني سمعتك.",
        icon: StarIcon,
    },
    {
        name: "لوحة التحكم الذكية",
        description:
            "تحكم فالعروض، تتبع الحجوزات، و شوف واش خدمتك كتحقق نجاح فـ الوقت الحقيقي.",
        icon: ChartBarIcon,
    },
];

const featureVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (i: number) => ({
        opacity: 1,
        y: 0,
        transition: {
            delay: 0.1 * i,
            duration: 0.5,
        },
    }),
};

const Features: React.FC = () => {
    return (
        <section id="features" className="section bg-white">
            <div className="container">
                <div className="text-center mb-16">
                    <motion.h2
                        initial={{ opacity: 0, y: -20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5 }}
                        className="text-3xl md:text-4xl font-bold text-gray-900"
                    >
                        المميزات اللي كتوفرهم {" "}
                        <span className="text-primary">Kauto.ma</span>
                    </motion.h2>
                    <motion.p
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        className="mt-4 text-lg text-gray-600 max-w-2xl mx-auto"
                    >
                        كلشي اللي خاصك باش توصل لعالم الرقمنة و تنافس مع الشركات الكبار
                    </motion.p>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {features.map((feature, index) => (
                        <motion.div
                            key={feature.name}
                            custom={index}
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            variants={featureVariants}
                            className="bg-gray-50 rounded-xl p-8 shadow-sm hover:shadow-md transition-shadow duration-300"
                        >
                            <div className="flex flex-col items-center text-center">
                                <div className={`flex items-center justify-center h-16 w-16 rounded-full bg-${index % 2 ? "secondary" : "primary"}/10 text-${index % 2 ? "secondary" : "primary"} mb-6`}>
                                    <feature.icon className="h-8 w-8" aria-hidden="true" />
                                </div>
                                <h3 className="text-xl font-semibold text-gray-900 mb-3">
                                    {feature.name}
                                </h3>
                                <p className="text-gray-600">{feature.description}</p>
                            </div>
                        </motion.div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Features;
