import React from "react";
import { motion } from "framer-motion";

const Contact: React.FC = () => {
    return (
        <section id="contact" className="section bg-white">
            <div className="container">
                <div className="max-w-4xl mx-auto text-center">
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5 }}
                        className="bg-gray-50 rounded-2xl p-12 shadow-sm"
                    >
                        <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
                            واش مستعد تدير <span className="text-primary">الرقمنة</span>  لوكالتك ديال كراء الطوموبيلات؟
                        </h2>
                        <p className="text-lg text-gray-600 mb-8 max-w-2xl mx-auto">
                            مئات الوكالات دخلات معانا و زادو فالخدمة ديالهم مع Kauto.ma سجل معنا اليوم و بدا تحولك الرقمي!

                        </p>
                        {/* <div className="flex items-center justify-center gap-8 mb-8">
                            <div className="flex items-center text-primary">
                                <svg
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                                    />
                                </svg>
                                <span className="ml-2 text-xl font-semibold">
                                    +212 5XX-XXXXXX
                                </span>
                            </div>
                        </div> */}

                        <a className="btn btn-primary text-lg px-8 py-4" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdUe83yaBBEJuKqdq5XL9yPNrK3OH3piKkBor4NsCb-hPFUrw/viewform?usp=header">
                            <button>
                                حجز بلاصتك اليوم
                            </button>
                        </a>
                    </motion.div>
                </div>
            </div>
        </section>
    );
};

export default Contact;
