import React from "react";
import { motion } from "framer-motion";
import {
    BuildingStorefrontIcon,
    GlobeAltIcon,
    ShieldCheckIcon,
} from "@heroicons/react/24/outline";
import HeroImage from '../assets/Kauto.ma-2.png'

const About: React.FC = () => {
    return (
        <section id="about" className="section bg-gray-50">
            <div className="container">
                <div className="text-center mb-16">
                    <motion.h2
                        initial={{ opacity: 0, y: -20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5 }}
                        className="text-3xl md:text-4xl font-bold text-gray-900"
                    >
                        علاش درنا <span className="text-primary">Kauto.ma</span>
                    </motion.h2>
                    <motion.p
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        className="mt-4 text-lg text-gray-600 max-w-3xl mx-auto"
                    >
                        الهدف ديالنا هو نعطيو فرصة لصغار وكالات كراء الطوموبيلات باش ينافسو فالسوق و يكبرو خدمتهم
                    </motion.p>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
                    <motion.div
                        initial={{ opacity: 0, x: -20 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5 }}
                        className="order-2 lg:order-1"
                    >
                        <div className="space-y-8">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">
                                        <BuildingStorefrontIcon
                                            className="h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    </div>
                                </div>
                                <div className="mr-4">
                                    <h3 className="text-xl font-semibold text-gray-900">
                                        خليوك تقابل الكبار فالسوق
                                    </h3>
                                    <p className="mt-2 text-gray-600">
                                        الشركات الكبار عندهم مواقع إلكترونية وعندهم الكليان ديالهم، ولكن مع Kauto.ma حتى الوكالات الصغيرة تقدر تبان فالبحث، تجيب كليان، وتكرّي بسهولة بلا ما تحتاج تعرف على التكنولوجيا ولا تصرف بزاف!
                                    </p>
                                </div>
                            </div>

                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-secondary text-white">
                                        <GlobeAltIcon className="h-6 w-6" aria-hidden="true" />
                                    </div>
                                </div>
                                <div className="mr-4">
                                    <h3 className="text-xl font-semibold text-gray-900">
                                        ساهل ومافيه حتى صداع
                                    </h3>
                                    <p className="mt-2 text-gray-600">
                                        Kauto.ma منصّة خفيفة وساهلة، كتمكنك تسيّر الطوموبيلات، الحجوزات، والعلاقة مع الكليان فبلاصا وحدة، بلا صداع ديال الوراق ولا اللوجستيك. ما كاين حتى حاجة صعيبة، غير التركيز على الخدمة ديالك.
                                    </p>
                                </div>
                            </div>

                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-primary text-white">
                                        <ShieldCheckIcon className="h-6 w-6" aria-hidden="true" />
                                    </div>
                                </div>
                                <div className="mr-4">
                                    <h3 className="text-xl font-semibold text-gray-900">
                                        بني المصداقية ديالك و زيد فالتقييمات
                                    </h3>
                                    <p className="mt-2 text-gray-600">
                                        الثقة عند الكليان هي كلشي! مع Kauto.ma غادي تقدر تجمع تقييمات حقيقية من الكليان، وهذا غادي يعطيك سمعة زوينة فالسوق و يخلي الناس كثر يكريو من عندك.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </motion.div>

                    <motion.div
                        initial={{ opacity: 0, x: 20 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        className="order-1 lg:order-2"
                    >
                        <div className="relative">
                            {/* <div className="aspect-w-16 aspect-h-9 rounded-xl overflow-hidden bg-gradient-to-r from-primary to-primary-light p-1">
                <div className="bg-white rounded-lg p-6">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="bg-gray-100 rounded-lg p-4 flex flex-col items-center justify-center">
                      <div className="text-4xl font-bold text-primary mb-2">
                        85%
                      </div>
                      <div className="text-sm text-gray-600 text-center">
                        Increase in bookings for small agencies
                      </div>
                    </div>
                    <div className="bg-gray-100 rounded-lg p-4 flex flex-col items-center justify-center">
                      <div className="text-4xl font-bold text-primary mb-2">
                        500+
                      </div>
                      <div className="text-sm text-gray-600 text-center">
                        Small rental agencies onboarded
                      </div>
                    </div>
                    <div className="bg-gray-100 rounded-lg p-4 flex flex-col items-center justify-center">
                      <div className="text-4xl font-bold text-primary mb-2">
                        24/7
                      </div>
                      <div className="text-sm text-gray-600 text-center">
                        Customer support and assistance
                      </div>
                    </div>
                    <div className="bg-gray-100 rounded-lg p-4 flex flex-col items-center justify-center">
                      <div className="text-4xl font-bold text-primary mb-2">
                        30%
                      </div>
                      <div className="text-sm text-gray-600 text-center">
                        Average cost reduction for agencies
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 bg-secondary/10 rounded-lg p-4 border-l-4 border-secondary">
                    <p className="text-gray-700 italic">
                      "Kauto.ma transformed our small family business. We now
                      compete with major rental companies and have doubled our
                      customer base in just 6 months."
                    </p>
                    <p className="mt-2 text-gray-600 font-medium">
                      — Mohammed, Casablanca Car Rentals
                    </p>
                  </div>
                </div>
              </div> */}
                            <img src={HeroImage} />

                            {/* <div className="absolute -z-10 inset-0 bg-secondary/20 rounded-xl transform translate-x-4 translate-y-4"></div> */}
                        </div>
                    </motion.div>
                </div>

                <div className="mt-16 text-center">
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5 }}
                    >
                        <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdUe83yaBBEJuKqdq5XL9yPNrK3OH3piKkBor4NsCb-hPFUrw/viewform?usp=header" className="btn btn-primary">
                            سجّل فـ Kauto.ma دابا 🚀
                        </a>
                    </motion.div>
                </div>
            </div>
        </section>
    );
};

export default About;
