import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Image1 from '../assets/car-exmpl.png'
import Image2 from '../assets/dashboard.png'
import Image3 from '../assets/car.png'
import Image4 from '../assets/agency.png'

const Fleet: React.FC = () => {
    // Array of all images
    const images = [Image1, Image2, Image3, Image4];
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    // Setup automatic sliding
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000); // Change image every 3 seconds

        return () => clearInterval(interval); // Cleanup on unmount
    }, [images.length]);

    // // Function to handle manual navigation
    // const goToSlide = (index) => {
    //     setCurrentImageIndex(index);
    // };
    return (
        <section id="fleet" className="section bg-gray-50">
            <div className="container">
                <div className="text-center mb-12">
                    <motion.h2
                        initial={{ opacity: 0, y: -20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5 }}
                        className="text-3xl md:text-4xl font-bold text-gray-900"
                    >
                        كيفاش غادي تبان  <span className="text-primary">الواجهة ديالك</span>{" "}
                        فـ Kauto.ma
                    </motion.h2>
                    <motion.p
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        className="mt-4 text-lg text-gray-600 max-w-2xl mx-auto"
                    >
                        تجربة سلسة على جميع الأجهزة – الحاسوب، التابليت، والتليفون.
                    </motion.p>
                </div>

                <div className="relative max-w-6xl mx-auto">
                    {/* Desktop Dashboard */}
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5 }}
                        className="relative z-30 bg-white rounded-xl shadow-xl"
                    >
                        <div className="relative bg-gray-800 rounded-t-xl h-8 flex items-center px-4">
                            <div className="flex gap-x-2">
                                <div className="w-3 h-3 rounded-full bg-red-500"></div>
                                <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
                                <div className="w-3 h-3 rounded-full bg-green-500"></div>
                            </div>
                        </div>
                        <div className="p-4">
                            <div className="bg-gray-50 rounded-lg aspect-[16/9] overflow-auto">
                                <div className="relative h-full">
                                    {images.map((img, index) => (
                                        <motion.div
                                            key={index}
                                            initial={{ opacity: 0 }}
                                            animate={{
                                                opacity: currentImageIndex === index ? 1 : 0,
                                                zIndex: currentImageIndex === index ? 1 : 0
                                            }}
                                            transition={{ duration: 0.5 }}
                                            className="absolute inset-0"
                                        >
                                            <img
                                                src={img}
                                                alt={`Slide ${index + 1}`}
                                                className="w-full"
                                            />
                                        </motion.div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </motion.div>

                    {/* Tablet */}
                    {/* <motion.div
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="absolute left-0 bottom-0 w-72 transform -translate-x-24 translate-y-1/3 z-20"
          >
            <div className="bg-gray-800 rounded-xl p-2">
              <div className="bg-black rounded-lg aspect-[4/3] overflow-hidden">
                <img
                  src="https://placehold.co/800x600/0D6EFD/FFFFFF?text=Tablet+View"
                  alt="Tablet Interface"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="mt-2 flex justify-center">
                <div className="w-8 h-8 rounded-full border-2 border-gray-600"></div>
              </div>
            </div>
          </motion.div> */}

                    {/* Mobile */}
                    {/* <motion.div
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="absolute right-0 bottom-0 w-48 transform translate-x-24 translate-y-1/4 z-10"
          >
            <div className="bg-gray-800 rounded-[2.5rem] p-2">
              <div className="relative bg-black rounded-[2rem] aspect-[9/19.5] overflow-hidden">
                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-1/2 h-7 bg-black rounded-b-xl"></div>
                <img
                  src="https://placehold.co/750x1624/0D6EFD/FFFFFF?text=Mobile+View"
                  alt="Mobile Interface"
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          </motion.div> */}
                </div>

                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 0.6 }}
                    className="mt-20 text-center"
                >
                    <a className="btn btn-primary" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdUe83yaBBEJuKqdq5XL9yPNrK3OH3piKkBor4NsCb-hPFUrw/viewform?usp=header">
                        بدا الخدمة دابا 🚀
                    </a>
                </motion.div>
            </div>
        </section>
    );
};

export default Fleet;
