import React from "react";
import { motion } from "framer-motion";
import HeroImage from '../assets/Kauto.ma-1.png'

const Hero: React.FC = () => {
    return (
        <section className="relative overflow-hidden bg-white py-16 sm:py-24">
            <div className="container">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        className="text-center lg:text-left"
                    >
                        <h1 className="text-4xl text-right md:text-5xl font-bold text-gray-900 leading-tight">
                            دخل عالم  <span className="text-primary">الرقمنة</span>   في كراء الطوموبيلات
                        </h1>
                        <p className="mt-6 text-right text-lg text-gray-600 max-w-2xl mx-auto mx-0">
                            حّل أول وكالة رقمية ديالك لكراء الطوموبيلات وسبق المنافسين الكبار من اليوم!  بان فالسوق، جذب كليان جداد، و خليك في المقدمة.
                        </p>
                        <div className="mt-10 flex lg:justify-start">
                            <a className="btn btn-primary   text-center text-lg px-8 py-4" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdUe83yaBBEJuKqdq5XL9yPNrK3OH3piKkBor4NsCb-hPFUrw/viewform?usp=header">
                                حجز بلاصتك اليوم
                            </a>
                        </div>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, scale: 0.9 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        className="relative"
                    >
                        <div className="relative z-10 flex items-center justify-center">
                            <div className="relative">
                                {/* Car illustration */}
                                <img src={HeroImage} />
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>

            {/* Decorative elements */}
            <div className="absolute top-20 left-10 w-20 h-20 bg-secondary/30 rounded-full blur-2xl" />
            <div className="absolute bottom-20 right-10 w-32 h-32 bg-primary/20 rounded-full blur-2xl" />
        </section>
    );
};

export default Hero;
