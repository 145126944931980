import React from "react";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import Logo from '../assets/logo.png'

const navigation = [
    { name: "Our Fleet", href: "#fleet" },
    { name: "About", href: "#about" },
    { name: "Features", href: "#features" },
    { name: "Contact", href: "#contact" },
];

const Navbar: React.FC = () => {
    return (
        <Disclosure as="nav" className="bg-white shadow-sm ">
            {({ open }) => (
                <>
                    <div className="container">
                        <div className="relative flex items-center justify-between h-20">
                            <div className="flex items-center justify-between w-full">
                                <div className="flex-shrink-0">
                                    <a href="/" className="flex items-center">
                                        <img src={Logo} width="120" />
                                        {/* <span className="text-2xl font-bold text-gray-800">
                                            Kauto
                                        </span>
                                        <span className="text-2xl font-bold text-primary">.ma</span> */}
                                    </a>
                                </div>
                                {/* <div className="hidden md:block">
                                    <div className="flex items-center gap-x-8">
                                        {navigation.map((item) => (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                className="text-gray-600 hover:text-primary font-medium"
                                            >
                                                {item.name}
                                            </a>
                                        ))}
                                    </div>
                                </div> */}
                                {/* <div className="hidden md:flex items-center space-x-4">
                                  
                                    <a className="btn btn-primary text-lg px-8 py-4  w-full" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdUe83yaBBEJuKqdq5XL9yPNrK3OH3piKkBor4NsCb-hPFUrw/viewform?usp=header">
                                        حجز بلاصتك اليوم
                                    </a>
                                </div> */}
                            </div>
                            {/* <div className="flex md:hidden">
                                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div> */}
                        </div>
                    </div>

                    <Disclosure.Panel className="md:hidden">
                        <div className="px-2 pt-2 pb-3 space-y-1">
                            {/* {navigation.map((item) => (
                                <Disclosure.Button
                                    key={item.name}
                                    as="a"
                                    href={item.href}
                                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-primary hover:bg-gray-50"
                                >
                                    {item.name}
                                </Disclosure.Button>
                            ))} */}
                            {/* <div className="pt-4 pb-3 border-t border-gray-200">
                                <div className="flex items-center px-5">


                                    <a className="btn btn-primary text-center text-lg px-8 py-4  w-full" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdUe83yaBBEJuKqdq5XL9yPNrK3OH3piKkBor4NsCb-hPFUrw/viewform?usp=header">
                                        حجز بلاصتك اليوم
                                    </a>
                                </div>
                            </div> */}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
};

export default Navbar;
